'use client';

import Error from 'next/error';

const GlobalError = ({ error }: { error: unknown }) => {
  console.error('An error occurred:', error);

  return (
    <html>
      <body>
        <Error statusCode={500} />
      </body>
    </html>
  );
};

export default GlobalError;
